.error{
  
      h1 {
        font-size: 2.5rem;
        color: #263238;
        font-weight: 600;
        position: relative;
        top: 0;
      }
  
      p {
        color: #546e7a;
        font-weight: 500;
      }
  
      .vertically-center {
        margin-top: 100px;
      }
  
      .btn-secondary {
        background-color: #efe117 !important;
        border: 1px solid #efe117 !important;
        font-size: 14px !important;
        color: #4e4e4e !important;
        box-shadow: none;
        padding: 10px 20px;
        margin: 0;
        line-height: 1.5;
        border-radius: .25rem;
        &:not([disabled]):not(.disabled){
          &:hover, &:active, &:focus{
            background-color: #fff !important;
            border: 1px solid #4e4e4e !important;
            color: #4e4e4e !important;
        }
        }
        
      }
  
      .img-wrapper {
        display: flex;
        margin-top: 48px;
        justify-content: center;
        margin-bottom: 48px;
      }
  
      .img-dimension {
        width: 560px;
        height: auto;
        max-width: 100%;
        max-height: 300px;
      }
}