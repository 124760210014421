.custom-date-picker {
	position: relative;
	.input-wrapper {
		position: relative;
		.icon {
			svg {
				width: 26px;
				height: 26px;
				top: calc(50% - 12px) !important;
				position: absolute;
				right: 7px;
				@media (max-width: 420px) {
					right: 4px;
				}
			}
		}
		img {
			position: absolute;
			left: 10px;
			top: 11px;
			height: 17px;
			width: 17px;
			@media (max-width: 420px) {
				left: 8px;
			}
		}
		input {
			height: 42px;
			width: 100%;
			padding: 11px 35px !important;
			cursor: pointer;
			@media (max-width: 420px) {
				padding: 11px 25px 11px 30px !important;
			}
		}
	}
	.auto-dimensions {
		height: auto !important;
		top: -69px !important;
	}
	.date-container {
		border-radius: 3px;
		padding: 10px;
		background-color: white;
		position: absolute;
		z-index: 6;
		top: -200px;
		display: flex;
		justify-content: flex-start;
		// width: 290px;
		@media (min-width: 576px) {
			width: max-content
		}
		.left {
			padding: 5px;
			display: flex;
			flex-direction: column;
			@media (max-width: 576px) {
				padding: 5px 15px 10px;
			}
			.item {
				cursor: pointer;
				padding: 7px;
				&.excluded {
					pointer-events: none;
					color: #ccc;
				}
				@media (max-width: 576px) {
					width: 240.3px;
				}
			}
			.active {
				background-color: rgba(48, 107, 207, 0.12) !important;
			}
		}
		.right {
			width: 57%;
			position: relative;
			.react-datepicker-popper {
				transform: none !important;
				margin: 0;
				position: relative !important;
				.react-datepicker__triangle {
					border: none;
					margin-top: 0;
					display: none;
				}
			}
			.react-datepicker__input-container {
				display: none;
			}
			.react-datepicker {
				border: 1px solid #e6e6e6;
				.react-datepicker__navigation {
					&--next {
						border-left-color: #333333;
					}
					&--previous {
						border-right-color: #333333;
					}
				}
			}
		}
	}
	.backdrop {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 5;
		background-color: #000 !important;
		opacity: 0.2;
	}
}
