/* Header */

header {
	&.delivery-status-header{
		height: 135px;
	}
	background: $color-secondary;
	position: relative;
	height: 220px;
	padding-top: 20px;
	@media (min-width: 576px) {
		height: 205px;
	}
	@media (min-width: 1024px) {
		padding-top: 0;
		height: 135px;
	}
	.container {
		display: flex;
		padding-top: 0px;
		height: 90px;
		flex-direction: column;
		@media (max-width: 576px) {
			margin-right: 15px;
			margin-left: 15px;
			width: auto;
		}
		@media (min-width: 767px) {
			padding-top: 19px;
		}
		.header-btn {
			background: none;
			color: $dark-grey !important;
			border: 1px solid #cbbe00;
			padding: 10px;
			@include font-size(14px);
			border-radius: 5px;
			text-transform: uppercase;
			font-weight: $font-weight-semibold;
			display: inline-block;
			height: 42px;
			min-width: 164px;
			text-align: center;
			cursor: pointer;

			&.hide {
				display: none;
			}
			&:hover {
				text-decoration: none;
				background: rgba(19, 20, 21, 0.2);
			}

			&.logout-btn {
				min-width: 40px;

				&:focus {
					text-decoration: none;
					box-shadow: none;
				}

				img {
					width: 16px;
					margin-top: -3px;
				}
			}

			&.dropdown-toggle {
				&::after {
					display: none;
				}
				&:not(:disabled):not(.disabled).active,
				&:not(:disabled):not(.disabled):active {
					background: none;
					border-color: #cbbe00;

					box-shadow: none;
				}
			}
		}

		.show > .btn-primary.dropdown-toggle {
			background: none;
			text-decoration: none;
			box-shadow: none;

			&:hover,
			&:focus,
			&:active {
				outline: none;
			}
		}
		.dropdown-menu {
			margin-top: 10px;
			position: relative;
			z-index: 10;

			// &::before {
			//     position: absolute;
			//     content: url(../../images/rectangle.png);
			//     top: -15px;
			//     z-index: -1;
			//     right: -10px;
			//     z-index: -1;

			// }
			.dropdown-item:hover {
				background: none;
			}
		}
		.dropdown-menu-right.lock-icon {
			&::after {
				content: url(../../images/icons/black-lock.png);
				position: absolute;
				top: -12px;
				right: -10px;
				transform: scale(0.5);
			}
		}
		.row {
			.support-col,
			.partner-logo,
			.logout-col {
				@media (max-width: 576px) {
					padding-left: 5px;
					padding-right: 5px;
				}
				@media (max-width: 425px) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
}
.quickAccessOptions {
	position: absolute;
	width: 310px;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
	text-align: left;
	padding: 14px 18px;
	margin-top: 4px;
	right: 0;
	left: -248px;
	z-index: 999;
	&.no-access {
		width: 225px;
		left: -173px;
		&::before {
			left: 190px;
		}
	}
	.header-btn {
		cursor: pointer;
		display: block;
	}
	.MuiFormControlLabel-labelPlacementStart {
		margin: 0;
		cursor: default;
		.MuiSwitch-root {
			width: 32px;
			margin-right: 0;
			height: 18px;
		}
		.MuiFormControlLabel-label {
			flex: 1;
		}
		.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
			opacity: 1;
			background-color: #35c758;
		}
		.MuiSwitch-switchBase {
			top: 1px;
		}
		.MuiSwitch-switchBase.Mui-checked {
			transform: translateX(15px);
		}
		.MuiTypography-body1 {
			font-family: inherit;
			font-weight: $font-weight-semibold;
			@include font-size(14px);
		}
		.MuiSwitch-thumb {
			width: 14px;
			height: 14px;
		}
		.MuiSwitch-colorSecondary {
			padding: 1px;
		}
		.MuiSwitch-switchBase {
			color: #ffffff;
			opacity: 1;
		}
		.MuiSwitch-track {
			background-color: #e9e9eb;
			border: none;
		}
	}
	&::before {
		position: absolute;
		content: "";
		border-color: transparent;
		border-style: solid;
		border-width: 0.4rem 0.4rem 0.4rem 0;
		border-right-color: #fff;
		top: -9px;
		transform: rotate(90deg);
		left: 266px;
	}
	.header-menu-btn {
		@include font-size(14px);
		font-family: inherit;
		border: none;
		padding: 0;
		margin: 0;
		font-weight: inherit;
		display: inline;
		text-transform: inherit;
		cursor: pointer;
		&.logout-btn {
			cursor: pointer;
			color: #71767a;
			&:hover {
				color: #71767a;
			}
		}
		&:hover {
			text-decoration: none;
			background: none;
		}
	}
	p {
		@include font-size(11px);
	}
	span {
		font-weight: bold;
	}
	hr {
		margin: 5px 0 5px 0;
	}
	.MuiTypography-body1,
	a {
		font-family: inherit;
		@include font-size(14px);
	}
	.admin-access-desc {
		margin-left: 4px;
	}
}

.quickAccessOptions {
	position: absolute;
	width: 310px;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
	text-align: left;
	padding: 14px 18px;
	margin-top: 4px;
	right: 0;
	left: -248px;
	z-index: 999;
	&.no-access {
		width: 225px;
		left: -173px;
		&::before {
			left: 190px;
		}
	}
	.header-btn {
		cursor: pointer;
		display: block;
	}
	.MuiFormControlLabel-labelPlacementStart {
		margin: 0;
		cursor: default;
		.MuiSwitch-root {
			width: 32px;
			margin-right: 0;
			height: 18px;
		}
		.MuiFormControlLabel-label {
			flex: 1;
		}
		.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
			opacity: 1;
			background-color: #35c758;
		}
		.MuiSwitch-switchBase {
			top: 1px;
		}
		.MuiSwitch-switchBase.Mui-checked {
			transform: translateX(15px);
		}
		.MuiTypography-body1 {
			font-family: inherit;
			font-weight: $font-weight-semibold;
			@include font-size(14px);
		}
		.MuiSwitch-thumb {
			width: 14px;
			height: 14px;
		}
		.MuiSwitch-colorSecondary {
			padding: 1px;
		}
		.MuiSwitch-switchBase {
			color: #ffffff;
			opacity: 1;
		}
		.MuiSwitch-track {
			background-color: #e9e9eb;
			border: none;
		}
	}
	&::before {
		position: absolute;
		content: "";
		border-color: transparent;
		border-style: solid;
		border-width: 0.4rem 0.4rem 0.4rem 0;
		border-right-color: #fff;
		top: -9px;
		transform: rotate(90deg);
		left: 266px;
	}
	.header-menu-btn {
		@include font-size(14px);
		font-family: inherit;
		border: none;
		padding: 0;
		margin: 0;
		font-weight: inherit;
		display: inline;
		text-transform: inherit;
		cursor: pointer;
		&.logout-btn {
			cursor: pointer;
			color: #71767a;
			&:hover {
				color: #71767a;
			}
		}
		&:hover {
			text-decoration: none;
			background: none;
		}
	}
	p {
		@include font-size(11px);
	}
	span {
		font-weight: bold;
	}
	hr {
		margin: 5px 0 5px 0;
	}
	.MuiTypography-body1,
	a {
		font-family: inherit;
		@include font-size(14px);
	}
	.admin-access-desc {
		margin-left: 4px;
	}
}

.logo {
	height: auto;
	margin-top: -3px;
	margin-left: 1px;
}
.powered-by {
	margin-left: 15px;
	height: 40px;
	@media (min-width: 576px) {
		margin-left: 30px;
	}
	p {
		text-transform: uppercase;
		@include font-size(10px);
		font-weight: $font-weight-semibold;
		opacity: 0.6;
		margin-bottom: 0;
	}
}

.partner-logo {
	max-width: 157px;
	margin-left: 6px;
	height: 60px;
	@media (max-width: 767px) {
		margin: 8px auto;
	}
	img {
		max-width: 100%;
		max-height: 100%;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles go here */
			width: 100%;
		}
	}
}
.ipad-landscape-view {
	display: none;
	@media (min-width: 1024px) {
		display: block;
	}
}
.quote-only {
	height: 50px;
	border-bottom: 1px solid #a2a2a2;
	margin-bottom: 20px;
}
.ipad-portrait-view {
	display: block;
	&.error {
		.img-logo {
			width: 160px;
			height: auto;
		}
		.vertically-center {
			margin-top: 0;
		}
	}
	@media (min-width: 1024px) {
		display: none;
	}
}
.notificationBox {
	text-align: center;
	height: auto;
	background-color: black;
	padding: 7px 43px;
	color: lime;
	font-size: 18px;
	img {
		width: 18px;
		right: 0;
		position: absolute;
		margin-right: 15px;
		top: 22px;
		cursor: pointer;
	}
	
}
@media print {
	.ipad-portrait-view {
		display: none !important;
	}
	.ipad-landscape-view {
		display: block !important;
	}
}
.portrait {
	height: 40vh;
	p {
		text-align: center;
		vertical-align: middle;
		padding: 114px;
		font-family: inherit;
	}
	@media (min-width: 320px) {
		p {
			@include font-size(14px);
			padding: 91px 0;
		}
	}
	@media (min-width: 768px) {
		p {
			@include font-size(19px);
			padding: 122px 60px;
		}
	}
}

.portrait {
	height: 40vh;
	p {
		text-align: center;
		vertical-align: middle;
		padding: 114px;
		font-family: inherit;
	}
	@media (min-width: 320px) {
		p {
			@include font-size(14px);
			padding: 91px 0;
		}
	}
	@media (min-width: 768px) {
		p {
			@include font-size(19px);
			padding: 122px 60px;
		}
	}
}

.reset,
.back {
	text-transform: uppercase;
	@include font-size(14px);
	color: $light-grey4;
	font-weight: $font-weight-semibold;
	padding-left: 24px;
	position: relative;
	top: -2px;
	cursor: pointer;
	&:hover {
		color: $light-grey6;
		padding-left: 24px;
		position: relative;
		top: -2px;
		cursor: pointer;
	}
}
.reset {
	background: url(../../images/icons/reset.png) no-repeat 7px center;
	background-size: 12px;
	&:hover {
		background: url(../../images/icons/reset-hover.png) no-repeat 7px center;
		background-size: 12px;
	}
}

.back {
	background: url(../../images/icons/back.png) no-repeat 11px center;
	background-size: 12px;
	padding-left: 29px;
	&:hover {
		background: url(../../images/icons/back-hover.png) no-repeat 11px center;
		background-size: 12px;
		padding-left: 29px;
	}
}
.support {
	margin: 0 0 15px;
	vertical-align: top;
	p {
		@include font-size(13px);
		text-transform: capitalize;
		font-weight: $font-weight-normal;
		right: 14px;
		margin-top: 4px;
		opacity: 0.8;
		&.phone {
			margin-bottom: -6px;
		}
		a {
			@include font-size(13px);
			color: #131415;
			text-transform: lowercase;
			&:hover {
				color: #131415;
			}
			&:focus {
				outline: none;
			}
		}
	}

	strong {
		@include font-size(13px);
		font-weight: $font-weight-semibold;
		opacity: 0.69;
	}
	span {
		@include font-size(13px);
		font-weight: bold;
	}
	img {
		margin-left: 10px;
		margin-top: -1px;
		width: 12px;
		margin-right: 6px;
	}
}

.partner-logo img {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
	filter: gray;
}

// Date-Range-picker modal
.fade.modal-backdrop.show {
	z-index: 0;
}
.modal {
	overflow-y: hidden !important;
}
