.modal-open {
	.main-wrapper {
		filter: blur(3px);
	}
	.no-blur {
		filter: none !important;
	}
}

.modal-backdrop.show {
	background-color: #fff;
}

.custom-modal {
	position: fixed;
	top: 38px;
	bottom: 0;
	margin-bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	padding: 0;
	margin-top: 0;
	&.modal-dialog {
		min-width: 768px;
		@media (min-width: 992px) {
			min-width: 1004px;
		}
	}

	@media (min-width: 1200px) {
		&.modal-dialog {
			min-width: 1140px;
		}
	}
	.modal-content {
		border-radius: 20px;
		border: 0;
		box-shadow: 0px 1px 12px 5px #cbcaca91;
		// overflow: scroll;
		height: 89vh;
		&::-webkit-scrollbar {
			display: none;
		}
		// background-color: transparent;
	}
}

//show alert modal
.fade.modal-backdrop.show {
	background-color: #000;
	z-index: 9999;
}

.alert-modal {
	z-index: 9999;
	text-align: center;
	.modal-dialog {
		@media (min-width: 576px) {
			max-width: 562px !important;
		}
		.modal-content {
			// min-width: 556px;
			// min-height: 236px;
			.modal-body {
				padding: 30px;
				h2,
				p {
					font-weight: 600;
					text-align: left;
				}
				h2 {
					@include font-size(23px);
					margin-top: 6px;
					line-height: 1;
					margin-bottom: 0;
				}
				p {
					color: #525252;
					line-height: 1.4;
					margin-bottom: 25px;
					margin-top: 22px;
					@include font-size(15px);
					&.centered-text {
						line-height: 1.7;
						@include font-size(15px);
						text-align: center;
						margin-top: 28px;
					}
				}
				.btn {
					padding: 14px 28px;
					max-height: 57px;
					@include font-size(16px);
					margin-left: 0;
					min-width: 98px;
					margin-bottom: 6px;
				}
				.service-info {
					margin-top: 4px;
					@include font-size(13px);
				}
				.service-group {
					height: 192px;
					overflow-y: scroll;
					margin-bottom: 10px;
					overflow-x: hidden;
				}
				.service-list {
					:nth-child(1) {
						.form-check {
							background-color: transparent;
							padding: 0;
							border: none;
							border-radius: inherit;
						}
					}
					:nth-child(2) {
						padding-left: 0;
						div {
							display: flex;
							p {
								@include font-size(18px);
								margin: 0 !important;
								color: #131415;
							}
							span {
								@include font-size(18px);
								font-weight: 600;
								color: #71767a;
								position: absolute;
								right: 16px;
							}
						}
						p {
							@include font-size(13px);
							margin-top: 3px !important;
						}
					}
					.MuiRadio-colorSecondary {
						margin-top: 13px;
						padding: 0;
						&.Mui-checked {
							color: #316ed4;
							&:hover {
								background-color: transparent;
							}
						}
					}
				}
			}
			.modal-close {
				background-size: 16px;
				top: 10px;
				right: 10px;
				opacity: 0.7;
				z-index: 9;
			}
		}
	}
	&.alert-sm {
		.modal-dialog {
			@media (min-width: 576px) {
				max-width: 380px !important;
			}
			.modal-content {
				max-width: 380px;
				min-width: 380px !important;
			}
		}
	}
	&.alert-md {
		.modal-dialog {
			@media (min-width: 576px) {
				max-width: 460px !important;
			}
			.modal-content {
				max-width: 460px;
				min-width: 300px !important;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
