/* MIXINS */

/* Mixin for font size */
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}


/* Vendor Prefix */
@mixin prefix($property, $value) {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    -ms-#{$property}: $value;
    -o-#{$property}: $value;
    #{$property}: $value;
}


/* Vertically Align a Div in Desktop*/
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


/* Horizontal Align a Div in Desktop*/
@mixin horizontal-align($position: relative) {
    position: $position;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}


/* Reset a div that's vertically aligned in mobile */
@mixin reset-vertical-align($position: relative) {
    position: $position;
    top: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

//horizontal padding
@mixin h-pad($padding-left, $padding-right) {
    padding-left: $padding-left;
    padding-right: $padding-right;
}

//vertical padding
@mixin v-pad($padding-top, $padding-bottom) {
    padding-top: $padding-top;
    padding-bottom: $padding-bottom;
}

//vertical padding
@mixin border($border-width, $border-color) {
    border-width: $border-width;
    border-style: solid;
    border-color: $border-color;
}

//Font-Face Mixin
@mixin font-face($name, $font-files, $eot: false, $weight: false, $style: false) {
    $iefont: unquote("#{$eot}?#iefix");

    @font-face {
        font-family: quote($name);

        @if $eot {
            src: font-url($eot);
            $font-files: font-url($iefont) unquote("format('embedded-opentype')"), $font-files;
        }

        src: $font-files;

        @if $weight {
            font-weight: $weight;
        }

        @if $style {
            font-style: $style;
        }
    }
} 


//Transition Mixin
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
