//Estimates content
#get-estimate.hide {
	display: none;
}

#estimate-section {
	margin: auto;
	.col {
		padding: 0;
	}
	&.show {
		display: block;
	}
	.estimate-info {
		margin-top: 8px;
		@include font-size(13px);
		img {
			width: 16px;
			height: 16px;
			margin-bottom: 3px;
		}
		label {
			padding-left: 5px;
			@include font-size(13px);
		}
	}
}
#customer-pickup-window{
	span, p {
		font-size: 14px;
		margin-bottom: 0.5rem;
	}
	.form-label{
		font-weight: 600;
	}
	.select-date {
		@media (max-width : 768px) {
			margin-bottom: 1rem !important;
		}
		.react-datepicker__day--keyboard-selected{
			background-color: white;
			color: #000;
			&.react-datepicker__day--disabled{
				color: #ccc;
			}
		}
	}
	.pickup-level-select{
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
	}
}
@media print {
	.distance {
		display: block !important;
		padding-left: 0;
	}
}

.infoIcon {
	background: url(../../images/icons/info.png) no-repeat center;
	padding-left: 17px;
	margin-left: 8px;
	background-size: 16px;
	&:hover {
		background: url(../../images/icons/info-hover.png) no-repeat center;
		padding-left: 17px;
		margin-left: 8px;
		background-size: 16px;
	}
	&.infoTooltip {
		position: absolute;
		height: 17px;
		width: auto;
	}
}
.toolTipOverlay {
	color: #316ed4;
	z-index: 999;
	position: absolute;
	cursor: pointer;
}

.warning-icon {
	background: url(../../images/icons/warning.svg) no-repeat 7px 1px;
	padding-left: 30px;
	background-size: 16px 16px;
	margin-left: 15px;
	&:first-letter{
		text-transform: capitalize;
	}
}

.estimate {
	background: $light-grey5;
	border-radius: 0 5px 5px 0;
	&.h-max-content{
		@media (min-width : 768px) {
			width: max-content;
		}
	}
	label {
		padding: 9px 12px 0;
		margin-bottom: 0;
		@include font-size(18px);
		height: 42px;

		strong {
			margin-left: 10px;
			@media (max-width: 425px) {
				margin-left: 0;
				@include font-size(16px);
			}
		}
	}
	.btn {
		// float: right;
		font-weight: $font-weight-normal;
		cursor: pointer;
		img {
			display: inline-block;
			width: 24px;
			margin-left: 0;
			margin-top: -5px;
			@media (min-width: 576px) {
				width: 14px;
				margin-top: -3px;
				margin-left: 5px;
			}
			&.sm{
				width: 13px;
				vertical-align: -1px;
			}
		}
	}
}
.estimate-quote-only {
	label {
		margin: 10px;
		padding: 0;
		height: auto;
	}
	strong {
		@include font-size(22px);
	}
}
.empty-map {
	background-color: $light-grey1;
}

.metrics {
	margin-top: 16px;
	margin-bottom: 26px;
}

//dashboard modal content

.show-trip-details {
	height: 89vh;
	.map-container {
		height: 462px !important;
	}
	.content {
		color: $dark-grey;
		letter-spacing: 0;
		padding-top: 4px;
		padding-bottom: 26px;
		position: relative;
		label {
			@include font-size(13px);
			color: $dark-grey2;
			text-transform: uppercase;
			margin-bottom: 8px;
			line-height: 1;
		}
		p {
			margin-bottom: 3px;
			&.description {
				@include font-size(16px);
				line-height: 1;
				&.custom {
					@include font-size(18px);
				}
			}
			&.sub-title {
				@include font-size(13px);
			}
			&.phone-number {
				@include font-size(14px);
				padding-left: 17px;
				&:before {
					content: "";
					background-image: url("../../images/icons/phone.png");
					background-size: 10px;
					background-repeat: no-repeat;
					background-position: center;
					width: 10px;
					height: 10px;
					position: absolute;
					left: 0;
					margin-top: 6px;
				}
			}
		}
		.trim-cust-name {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.btn.cancel-delivery {
		background: transparent;
		padding: 0;
		margin-top: 5px;
		@include font-size(14px);
		color: $red !important;
		&:hover,
		&:active,
		&:focus {
			box-shadow: none;
			color: #c11c06 !important;
		}
	}
	h2 {
		margin-top: 39px;
		margin-bottom: 16px;
	}

	.left-sidebar {
		flex: 0 0 27%;
		max-width: 26%;
		padding: 27px !important;
		background-color: #f6f6f6;
		border-radius: 20px 0 0 0;
		height: 89vh;
		position: absolute;
		border-radius: 20px 0 0 20px;
		overflow-y: auto;
		.content {
			label {
				margin-bottom: 19px;
			}
			.customer-label {
				margin-bottom: 9px;
			}
		}
		.capitalizeName {
			text-transform: capitalize;
		}
	}
	.status-feed {
		.content {
			padding-top: 0;
			padding-bottom: 1px;
		}
		.delivery-status {
			position: relative;
			margin-top: 2px;
			div.trip-progress-icon {
				padding-bottom: 17px;
				position: relative;
				.content {
					height: 33px;
					padding-left: 42px;
					.status {
						@include font-size(14px);
						margin-bottom: 0;
						line-height: 1;
						font-weight: $font-weight-semibold;
						text-transform: initial;
						color: $dark-grey;
						vertical-align: middle;
					}
					.timeStamp {
						@include font-size(12px);
						line-height: 1;
						margin-bottom: 0;
						color: $dark-grey2;
					}
				}
				&:before {
					content: "";
					background-size: 12px;
					background-repeat: no-repeat;
					background-position: center;
					background-color: #f6f6f6;
					border: 1px solid #c4c4c4;
					width: 34px;
					height: 34px;
					border-radius: 50px;
					position: absolute;
					z-index: 1;
				}
				&:after {
					content: "";
					position: absolute;
					border-left: 2px dashed $yellow;
					left: 16px;
					height: 100%;
					top: 0;
					z-index: 0;
				}
				&:last-child:after {
					content: none;
				}
			}
			.trip-progress-icon {
				&.Assigning {
					&:before {
						background-image: url("../../images/icons/checked.png");
						background-color: $yellow;
						border: 1px solid $yellow;
						background-size: 14px;
					}
				}
				&.Awaiting {
					&:before {
						background-image: url("../../images/icons/checked.png");
						background-color: $yellow;
						border: 1px solid $yellow;
						background-size: 14px;
					}
				}
				&.Scheduled {
					&:before {
						background-image: url("../../images/icons/checked.png");
						background-color: #feee02;
						border: 1px solid #feee02;
						background-size: 14px;
					}
				}
				&.En {
					&:before {
						background-image: url("../../images/icons/trip-progress/marker.png");
						background-size: 14px;
					}
				}
				&.Driver {
					&:before {
						background-image: url("../../images/icons/trip-progress/location.png");
						background-size: 15px;
					}
				}
				&.Loading {
					&:before {
						background-image: url("../../images/icons/pickup-black.png");
						background-size: 12px;
					}
				}
				&.Driving {
					&:before {
						background-image: url("../../images/icons/trip-progress/driver-delivery.png");
						background-size: 19px;
					}
				}
				&.Unloading {
					&:before {
						background-image: url("../../images/icons/pickup-black.png");
						background-size: 12px;
						transform: rotate(180deg);
					}
				}
				&.Completed {
					&:before {
						background-image: url("../../images/icons/trip-progress/flag.png");
						background-size: 14px;
					}
				}
				&.completed {
					&:before {
						content: "";
						background-image: url("../../images/icons/checked.png");
						background-color: $yellow;
						border: 1px solid $yellow;
						background-size: 14px;
						transform: none;
					}
				}
				&.Canceled {
					&:before {
						content: "";
						background-image: url("../../images/icons/close-white.png");
						background-color: $red;
						border: 1px solid $red;
						background-size: 12px;
						transform: none;
					}
					label,
					p {
						color: $red !important;
					}
				}
			}
		}
		.trip-Canceled-text {
			margin-top: 14px;
			@include font-size(14px);
			color: $red;
		}
	}

	.detailed-information {
		padding: 23px 0;
		flex: 0 0 74%;
		max-width: 74%;
		margin-left: 26%;
		.scrollable-content {
			overflow-y: scroll;
			height: 75vh;
			overflow-x: hidden;
			margin-top: 56px;
			padding: 0 23px;
			.scheduled-details {
				border-top: 1px solid $light-grey1;
				padding-top: 6px;
				padding-bottom: 35px;
				border-bottom: 1px solid $light-grey1;
			}
			.trip-details {
				.content {
					padding-top: 3px;
					padding-bottom: 20px;
					padding-left: 46px !important;
					p {
						line-height: 1.3;
					}
				}
				.pickup-icon,
				.delivery-icon {
					&:before {
						width: 27px;
						height: 20px;
						background-size: 20px 20px;
						top: 8px;
					}
				}
				.pickup-icon {
					padding-bottom: 0;
					&:after {
						left: 12px;
					}
				}
				.distance {
					@include font-size(12px);
					margin-top: 4px;
					color: $dark-grey2;
				}
			}
			.scheduled-drivers {
				padding-top: 6px;
				.yellow-label {
					background-color: $yellow;
					@include font-size(11px);
					padding: 3px 12px;
					border-radius: 5px;
					margin-left: 3px;
					font-weight: $font-weight-semibold;
					line-height: 1;
					color: #968d09;
				}
				.content {
					padding-bottom: 9px;
					p {
						margin-top: 5px;
						margin-bottom: 0;
						&.trim-name {
							text-overflow: ellipsis;
							overflow: hidden;
							width: 105px;
							white-space: nowrap;
						}
					}
				}
				.media {
					.media-body {
						margin-left: 4px;
						padding-bottom: 0;
						.icon {
							width: 12px;
							margin-top: -10px;
							margin-right: 1px;
						}
					}
				}
			}
			.estimate-label {
				font-weight: $font-weight-normal;
				@include font-size(18px);
				margin-top: 12px;
				span {
					float: right;
					@include font-size(20px);
				}
			}
			.additional-details {
				margin-top: 42px;
				word-wrap: break-word;
				.content {
					margin-left: 8px;
					padding-bottom: 21px;
					padding-right: 23px;
					.description {
						line-height: 1.2;
					}
					.pallet-details {
						th {
							&:first-child {
								width: 35%;
							}
						}
					}
					&.secondary {
						padding-top: 4px;
						padding-bottom: 19px;
						label {
							margin-top: 3px;
						}
						label,
						.description {
							margin-bottom: 0;
						}
					}
				}
				th {
					@include font-size(13px);
					color: #131415;
					font-weight: normal;
					border-bottom: 1px solid #a2a2a2;
					border-top: none;
					padding-bottom: 6px;
					&:first-child {
						padding-left: 0;
						padding-right: 0;
					}
				}
				.items {
					cursor: pointer;
				}
				.empty-items {
					cursor: initial;
				}
				td {
					border-bottom: 1px solid #a2a2a2;
					vertical-align: top;
					font-size: 13px;
					&:first-child {
						padding-top: 15px;
						color: #131415;
						width: 90px;
						padding-left: 0;
						padding-right: 0;
					}
					&:nth-child(2) {
						padding-top: 15px;
						opacity: 1;
						line-height: 1.2;
					}
					&:nth-child(4) {
						padding-top: 8px;
						padding-right: 30px;
					}
				}
				.item-details {
					@include font-size(12px);
					opacity: 0.7;
				}
				.close-details {
					img {
						transform: rotate(180deg);
					}
				}
			}
			.verification-img {
				margin: 0 8px;
				background-color: #f6f6f6;
				border-radius: 11px;
				.img-thumbnail {
					padding: 0;
					background: none;
					border: none;
					width: 100%;
					height: auto;
					cursor: pointer;
					border-radius: 0;
				}
				.outside-container {
					max-width: 50%;
					float: left;
					padding: 15px;
				}
				.img-container {
					padding: 2px;
					height: 70px;
					overflow: hidden;
					margin-bottom: 2px;
				}
			}
		}
		.sticky-header {
			position: absolute;
			width: 100%;
			top: 0;
			height: 79px;
			background-color: $white;
			z-index: 99;
			border-top-right-radius: 20px;
			padding: 0 23px;
			.modal-close {
				top: 32px;
				right: 23px;
			}
		}
	}
}
.ReactModalPortal .ReactModal__Overlay {
	z-index: 9999 !important;
}
.modal-close {
	background-image: url("../../images/icons/close.png");
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 30px;
	right: 22px;
	cursor: pointer;
}
/* Success component*/
.success-trip-details {
	.pickup-icon,
	.delivery-icon {
		position: relative;
		&:before {
			content: "";
			background-size: 20px 20px;
			background-repeat: no-repeat;
			background-position: center;
			width: 38px;
			height: 21px;
			border-radius: 19px;
			position: absolute;
			z-index: 3;
			top: 4px;
		}
		.content {
			padding-left: 50px;
		}
	}
	.pickup-icon {
		padding-bottom: 104px;
		&:after {
			content: "";
			position: absolute;
			border-left: 2px dashed $yellow;
			height: 100%;
			top: 17px;
			left: 19px;
			z-index: 0;
		}
	}
	.delivery-icon {
		&:before {
			background-image: url("../../images/icons/icon-dropoff.svg");
		}
	}
	.pickup-icon {
		&:before {
			background-image: url("../../images/icons/icon-pickup.svg");
		}
	}
}

/* Delivery Status*/
.show-delivery-status {
	.content-section {
		@media (min-width: 768px) {
			margin-top: 10px;
			.desktop {
				top: 100px;
			}
			.desktop-no-rating {
				top: 10px;
				img {
					width: 100px;
				}
			}
			.desktop-1-driver {
				top: 60px;
			}
		}

		.driver-rating {
			// border-top: 1px solid $light-grey1;
			h6 {
				font-weight: 700;
				margin-bottom: 15px;
			}
		}
		@media (max-width: 767px) {
			.text-danger,
			.text-success {
				display: block;
				text-align: center;
			}
			.r-p-mobile {
				padding: 0;
			}
			.media-body {
				label {
					word-break: break-all;
				}
			}
		}
		@media (min-width: 767px) {
			.rate-btn {
				text-align: left !important;
			}
		}
	}
	.trip-completed {
		padding: 20px 0 10px 0;
		img {
			width: 72px;
			height: 72px;
			display: inline-block;
			position: absolute;
		}
		span {
			display: inline-block;
			margin-top: 10px;
			margin-left: 85px;
			font-size: 18px;
			font-weight: 700;
			position: relative;
		}
	}
	.yellow-label {
		background-color: #feee00;
		@include font-size(11px);
		padding: 3px 12px;
		border-radius: 5px;
		margin-left: 3px;
		font-weight: 600;
		line-height: 1;
		color: #968d09;
	}
	.sticky-header {
		label {
			font-size: 13px;
			opacity: 0.5;
			text-transform: uppercase;
		}
		@media (min-width: 768px) {
			border-bottom: 1px solid #d8d8d8;
			text-align: left !important;
			label {
				font-size: 25px;
				font-weight: bold;
				opacity: inherit;
				text-transform: capitalize;
			}
		}
	}
	.scheduled-details {
		.content {
			label {
				@include font-size(13px);
				color: #88898a;
				text-transform: uppercase;
				margin-bottom: 8px;
				line-height: 1;
			}
		}
		.pickup-icon {
			padding-bottom: 20px;
		}
	}

	.delivery-status {
		border-radius: 10px 10px 0 0;
		@media (min-width: 768px) {
			padding: 8px 9px;
			margin: 0;
			background-color: #ededed;
			.bbottom {
				border-bottom: 1px solid #d8d8d8;
			}
		}
		img {
			width: 33px;
			height: 33px;
		}
		span {
			font-size: 16px;
			font-weight: bold;
			margin: 4px auto;
		}
	}
	.delivery-info {
		background-color: #ededed;
		border-radius: 0 0 10px 10px;
		padding: 0 9px 10px 9px;
		.pickup-time,
		.pickup-distance,
		.pickup-delivery {
			color: #131415;
			@include font-size(13px);

			margin-top: 8px;
			&:nth-child(odd) {
				opacity: 0.7;
				text-transform: uppercase;
			}
			&:nth-child(even) {
				text-align: right;
				@media (min-width: 1024px) {
					@include font-size(16px);
				}
			}
		}
	}
	.scheduled-drivers {
		margin-top: 20px;
		h6 {
			opacity: 0.5;
			@include font-size(13px);
			text-transform: uppercase;
		}
		.content {
			p {
				margin-top: 5px;
				margin-bottom: 0;
			}
		}
		.media-body {
			margin-left: 4px;
			padding-bottom: 0;
		}
		.phone-icon {
			height: auto;
			width: 14px;
			margin-left: 5px;
			margin-bottom: 4px;
		}
		.call {
			width: 22px;
			height: 22px;
			cursor: pointer;
		}
		.description {
			word-break: break-all;
			padding: 0 10px 0 0;
		}
		.driver-2 {
			margin-top: 10px;
		}
		&.single-driver {
			@media (min-width: 767px) {
				margin-top: -90px;
			}
		}
		&.duo-driver {
			@media (min-width: 767px) {
				margin-top: -140px;
			}
			@media (min-width: 1024px) {
				margin-top: -130px;
			}
		}
	}
	.scrollable-content {
		overflow-y: scroll;
		height: 60vh;
		overflow-x: hidden;
		margin-top: 20px;
		@media (max-width: 1023px) {
			height: auto;
		}
	}

	@media (max-width: 767px) {
		.map {
			margin-top: 20px;
			.map-container {
				height: 340px !important;
			}
		}
	}
}
/*Trip already exists popup*/
.alreadyExists {
	text-align: left;
	label {
		font-weight: 700;
		margin-bottom: 0;
	}
	span {
		display: block;
		margin-bottom: 5px;
	}
}
.infoText {
	@include font-size(13px);
	margin: 10px 0;
}
/* Login Page*/

.login-container {
	height: 100vh;
	&.container {
		.row {
			height: 100%;
		}

		padding: 0 !important;
		margin: 0 !important;
		max-width: 100% !important;
		// @media (min-width: 768px) {
		// }
	}

	.img-holder {
		background: url("../../images/landing-bg.jpg") no-repeat;
		height: 100%;
		background-size: cover;
	}
	.login-form {
		text-align: center;
		margin-top: 80px;
		display: block;
		margin-left: 15px;
		margin-right: 15px;
		@media (min-width: 420px) {
			display: inline-block;
			margin-left: 0;
			margin-right: 0;
		}
		// @media (min-width: 1200px) {
		// 	width: 480px;
		// }

		form {
			margin-top: 142px;
			text-align: center;

			.form-group {
				position: relative;

				@media (min-width: 400px) {
					width: 380px;
				}
				&::after {
					content: "";
					width: 23px;
					height: 23px;
					position: absolute;
					top: 18px;
					right: 15px;
					background: url("../../images/icons/progress.png") no-repeat bottom
						right;
					background-size: 23px;
				}
			}
			input {
				border: none;
				border-bottom: 1px solid $light-grey1;
				border-radius: 0;
				width: 100%;
				display: inline-block;
				background-image: url("../../images/icons/lock.png") !important;
				background-repeat: no-repeat;
				background-size: 15px 17px;
				background-position: 8px 9px;
				padding-left: 31px;
				background-color: rgba(0, 0, 0, 0) !important;
				@media (min-width: 768px) {
					width: 90%;
				}
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			button {
				margin-top: 142px;
				margin-bottom: 30px;
				@media (min-width: 768px) {
					margin-top: 0;
				}
			}
		}
	}
}

.text-danger,
.invalid-feedback {
	color: $red !important;
	font-size: 13px;
}
.invalid {
	border: 1px solid $red;
}

.err-msg {
	@media (max-width: 768px) {
		font-size: 13px;
	}
}
