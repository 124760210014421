.card {
    box-shadow: 0px 3px 8px 0 rgba(0, 0, 0, 0.2);
    border:0;
    padding:10px 18px;
    height:100%;
    text-align: right;

    label{
        font-weight: $font-weight-semibold;
        @include font-size(13px);
        color:$dark-grey2;
        margin-bottom: 7px;
    }
    p{
        color:$dark-grey;
        @include font-size(29px);
        font-weight: $font-weight-semibold;
        margin-bottom: 5px;
        line-height: 1;
        span{
            @include font-size(17px);
            font-weight: $font-weight-normal;
            margin-left: 5px;
        }
        sup{
            top: -7px;
            left: -2px;
            font-size: 65%;
            font-weight: 400;
        }
    }
}

// Background images for the Dashboard Metrics cards
.total-deliveries .card{
    background: url(../../images/icons/total-deliveries.png) no-repeat;
    background-size: 26px;
    background-position: 14px 36px;
}

.avg-rating .card{
    background: url(../../images/icons/rating.png) no-repeat;
    background-size: 26px;
    background-position: 12px 36px;
}

.avg-cost .card{
    background: url(../../images/icons/cost.png) no-repeat;
    background-size: 32px 23px;
    background-position: 13px 39px;
}

.avg-distance .card{
    background: url(../../images/icons/distance.png) no-repeat;
    background-size: 28px 22px;
    background-position: 13px 40px;
}

.current-deliveries .card{
    background: url(../../images/icons/deliveries.png) no-repeat $yellow-light;
    background-size: 31px 22px;
    background-position: 14px 41px;
}
