body {
	font-family: $font-primary;
	color: $dark-grey;
}

.overlay {
	position: fixed;
	z-index: 34;
	opacity: 1;
	top: 0;
	left: 0;
	width: 1024px;
}
.main-wrapper {
	background: $light-grey;
	min-height: 100vh;
	background-image: url(../../images/background-image.png);
	background-size: cover;
	background-repeat: no-repeat;
}
.main-container {
	background: $white;
	border-radius: 20px;
	margin-top: -35px;
	padding: 10px 10px 40px 10px;
	position: relative;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
	//min-height: calc(100vh - 90px);
	margin-right: 5px;
	margin-left: 5px;

	@media (max-width: 576px) {
		width: auto;
	}
	@media (min-width: 425px) {
		margin-right: 10px;
		margin-left: 10px;
	}
	@media (min-width: 576px) {
		padding-right: 20px;
		padding-left: 20px;
		margin-left: auto;
		margin-right: auto;
	}
}

.map-container {
	height: 580px;
	border-radius: 10px;
	// overflow: hidden;
	&.quickAccess {
		height: 705px;
	}
	&.nohelperCheckbox {
		height: 520px;
	}
	&.nosoloduo {
		height: 395px;
	}
	&.soloduo-type-2 {
		height: 580px;
	}
	&.soloduo-type-3 {
		height: 700px;
	}
	&.soloduo-type-3-wt-checkbox {
		height: 750px;
	}
	&.delivery-map {
		height: 330px;
		margin-left: -10px;
		margin-right: -10px;
		&.driver-one {
			@media (min-width: 767px) {
				height: 510px;
			}
			@media (min-width: 1024px) {
				height: 420px;
			}
		}
		@media (min-width: 768px) {
			height: 540px;
			margin-left: 0;
			margin-right: 0;
		}
		@media (min-width: 1024px) {
			height: 460px;
			margin-left: 0;
			margin-right: 0;
		}
	}
	div[title="driver1"] {
		overflow: inherit !important;
		&:after {
			content: "";
			border-radius: 50%;
			height: 100%;
			width: 100%;
			position: absolute;
			animation: pulsate 1s ease-out;
			animation-iteration-count: infinite;
			opacity: 0;
			box-shadow: 0 0 1px 2px #2196f3;
			animation-delay: 0.1s;
			left: 0;
			top: 0;
		}
	}
	div[title="driver2"] {
		overflow: inherit !important;
		&:after {
			content: "";
			border-radius: 50%;
			height: 100%;
			width: 100%;
			position: absolute;
			animation: pulsate 1s ease-out;
			animation-iteration-count: infinite;
			opacity: 0;
			box-shadow: 0 0 1px 2px #2196f3;
			animation-delay: 0.1s;
			left: 0;
			top: 0;
		}
	}
}
@keyframes pulsate {
	0%,
	100% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

h1 {
	&.heading {
		@include font-size(30px);
		position: initial;
		font-weight: 600;
		margin-bottom: 24px;
		margin-top: 35px;
		@media (min-width: 768px) {
			margin-bottom: 30px;
		}
	}
}
.summary-container {
	h2 {
		margin-top: 0;
		line-height: 1;
		&.summary-label {
			border-bottom: 1px solid $light-grey1;
			padding-bottom: 16px;
			span {
				color: $blue;
				@include font-size(14px);
				margin-top: 4px;
				&.edit-label {
					cursor: pointer;
				}
				&:hover {
					color: $dark-blue;
				}
			}
		}
		&.estimate-label {
			border-top: 1px solid $dark-grey1;
			font-weight: 400;
			padding-top: 16px;
			margin-bottom: 0;
			@include font-size(18px);
		}
		span {
			float: right;
			cursor: pointer;
		}
	}
	&.grey {
		background: $light-grey5;
		border-radius: 10px;
		padding: 18px;
		// margin: 10px;
	}
	label {
		text-transform: uppercase;
		@include font-size(12px);
		color: $light-grey4;
		margin: 0 7px 0 0;
	}
	p {
		@include font-size(16px);
		line-height: 1.2;
	}
	i {
		@include font-size(14px);
		color: $light-grey4;
	}

	.m-b-104 {
		margin-bottom: 104px;
	}
}
.customer-summary-container {
	&.grey {
		background: $light-grey5;
		border-radius: 10px;
		padding: 18px;
	}
	label {
		text-transform: uppercase;
		font-size : 14px;
		font-weight: 600;
		margin: 0 0 0 0;
	}
}
address {
	@include font-size(16px);
	line-height: 1.2;
}

/* dashboard data table*/
.dataTable {
	.MuiPaper-rounded {
		box-shadow: none;
	}
	.MuiToolbar-gutters {
		padding-left: 10px;
		padding-right: 0;
		h6 {
			@include font-size(22px);
			font-weight: 700;
		}
	}
	&.DeliveryDate {
		.five-cols {
			table {
				th {
					&:nth-of-type(4) {
						// width: 40%;
					}
				}
			}
		}
		table {
			tr {
				cursor: pointer;
				td,
				th {
					padding-left: 10px;
					div {
						word-break: break-word;
					}
				}
			}
			td:nth-of-type(4) {
				text-transform: capitalize;
			}
			th {
				font-weight: 600;
				text-transform: uppercase;
				width: 16%;
				&:not(:first-child) {
					color: #89898a;
				}
				&:first-child {
					width: 16%;
					.MuiTableSortLabel-root {
						content: "";
						background-image: url(../../images/icons/caret-black.png);
						background-repeat: no-repeat;
						background-position: 6px 9px;
						background-size: 7px;
						width: 20px;
						height: 20px;
						position: absolute;
						path {
							display: none;
						}
					}
				}
			}
			td {
				div {
					word-break: break-word !important;
				}
			}
		}
		&.asc {
			.MuiTableSortLabel-active {
				transform: rotate(180deg);
			}
		}
	}
	&.ReceiptNumber {
		table {
			tr {
				cursor: pointer;
				td,
				th {
					padding-left: 10px;
					text-transform: uppercase;
					div {
						text-transform: uppercase;
					}
				}
			}
			th {
				font-weight: 600;
				&:nth-of-type(1) {
					width: 22%;
				}
				&:nth-of-type(3) {
					width: 15%;
				}
				&:nth-of-type(3) {
					width: 20%;
				}
				&:nth-of-type(4) {
					width: 26%;
				}
				&:not(:nth-of-type(2)) {
					color: #89898a;
				}
			}
		}
		.MuiTableSortLabel-active {
			content: "";
			background-image: url(../../images/icons/caret-black.png);
			background-repeat: no-repeat;
			background-position: 6px 9px;
			background-size: 7px;
			width: 20px;
			height: 20px;
			position: absolute;
			path {
				display: none;
			}
		}
		&.asc {
			.MuiTableSortLabel-active {
				transform: rotate(180deg);
			}
		}
	}

	.search {
		position: absolute;
		left: 158px;
		top: 12px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			/* IE10+ CSS styles go here */
			top: -2px;
		}
		.searchText {
			border: none;
			background: #f5f5f5;
			width: 193px;
			height: 40px;
			&::placeholder {
				color: #848585;
				@include font-size(14px);
			}
			padding-left: 37px;
			background-image: url(../../images/icons/search.png);
			background-repeat: no-repeat;
			background-position: 13px;
			background-size: 16px 16px;
		}
	}
	.closeIcon {
		background: url(../../images/icons/close.png) no-repeat center;
		width: 28px;
		height: 28px;
		display: inline-block;
		position: absolute;
		right: 5px;
		border-radius: 5px;
		top: 6px;
		background-size: 13px;
		cursor: pointer;
		background-color: rgb(245, 245, 245);
	}
	.caret {
		&:after {
			content: "";
			background-image: url(../../images/icons/caret-blue.png);
			background-repeat: no-repeat;
			background-position: 6px 11px;
			background-size: 7px;
			width: 20px;
			height: 20px;
			position: absolute;
		}
	}
	.datatables-noprint {
		pointer-events: none;
	}
}
.delivery-details {
	.mandateCheckbox {
		.form-check {
			.form-check-label {
				max-width: 900px;
			}
		}
	}
}
.mandateCheckbox {
	.form-check {
		.form-check-label {
			font-size: 13px !important;
			margin-left: 0 !important;
			display: inline-block;
			max-width: 500px;
			vertical-align: top;
			&::before {
				top: 4px !important;
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					/* IE10+ CSS styles go here */
					top: 3px !important;
				}
			}
		}
		.form-check-input {
			display: inline-block;
			width: 15px;
			vertical-align: top;
			&:checked + .form-check-label {
				&:after {
					top: 8px !important;
					@media all and (-ms-high-contrast: none),
						(-ms-high-contrast: active) {
						/* IE10+ CSS styles go here */
						top: 7px !important;
					}
				}
			}
		}
	}
}
.filterDropdown,
.mandateCheckbox {
	a,
	.filter {
		text-decoration: none;
		padding-right: 14px;
		color: $blue;
		font-weight: 600;
		span {
			color: #131415;
		}
	}
	.filter {
		cursor: pointer;
	}
	.form-group {
		margin-bottom: 2px;
		&:first-child {
			margin-bottom: 4px;
			.form-check-label {
				font-weight: 600;
			}
		}
	}
	.dropdown-menu {
		min-width: 217px;
		padding: 17px 20px 24px;
		transform: none !important;
		left: auto !important;
		right: 0 !important;
		top: 38px !important;
		box-shadow: 0px 3px 8px 0 rgba(0, 0, 0, 0.2);
		border: 0;
		border-radius: 10px;
		.inner-content {
			position: relative;
			z-index: 1;
		}
		&:before {
			content: "";
			background-color: inherit;
			position: absolute;
			width: 14px;
			height: 14px;
			top: -5px;
			right: 7%;
			transform: rotate(45deg);
			-webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
			filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
		}
		&:after {
			content: "";
			background-color: inherit;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 10px;
		}
	}
	.form-check {
		background-color: transparent;
		padding: 0;
		border-radius: 0;
		border: none;
		height: auto;
		.form-check-label {
			@include font-size(14px);
			margin-top: 0;
			margin-left: 14px;

			&::before {
				height: 14px;
				width: 13px;
				border: 1px solid;
				border-radius: 3px;
				left: 0px;
				top: 6px;
			}
		}

		.form-check-input {
			&:checked + .form-check-label {
				&:before {
					background-color: $blue;
				}

				&:after {
					height: 4px;
					width: 7px;
					border-left: 2px solid;
					border-bottom: 2px solid;
					transform: rotate(-45deg);
					border-radius: 0;
					color: $white;
					left: 3px;
					top: 10px;
				}
			}
		}
	}
	.btn-group {
		margin-top: 20px;
		margin-top: 11px;
		.btn {
			@include font-size(14px);
			padding: 5px 16px;
		}
	}
}

.btn-group {
	.btn {
		&:not(:last-child):not(.dropdown-toggle) {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		&.btn-reset,
		&.btn-clear {
			@include font-size(14px);
			margin-left: 8px;
			color: $light-grey4 !important;
			background: transparent !important;
			&:focus {
				box-shadow: none;
			}
			&:hover {
				color: $light-grey6 !important;
			}
		}
		&.btn-clear-red {
			color: $red !important;
			&:hover {
				color: #c11c06 !important;
			}

			&.noborder {
				border: none !important;
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}
}

.full-width {
	label {
		width: calc(100% - 31px);
	}
}
