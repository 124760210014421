.react-datepicker-wrapper{
    display: block !important;
    background: url(../../images/icons/calendar.png) no-repeat 13px center ;
    background-size: 14px 16px;

    input.date{
        width:100%;
        position:relative;
        background: url(../../images/icons/caret.png) no-repeat;
        background-size: 23px auto;
        background-position: center right;
        padding:8px 5px 9px 35px;
        height: 42px;
    }
}