.date-picker-modal{
    .modal-dialog{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100% !important;
        .modal-content{
            width: 840px;
            .modal-header{
                @include font-size(23px);
                font-weight: 600;
            }
            .close{
                background-size: 15px;   
                top: 10px;
                right: 10px;
                opacity: 0.7;
                z-index: 9;
                background-image: url(../../images/icons/close.png);
                background-repeat: no-repeat;
                background-position: center;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 30px;
                right: 34px;
                cursor: pointer;
                span{
                    display: none;
                }
            }
            .modal-body{
                padding: 10px 2rem;
                .calender-container{
                    justify-content: center;
                    .MuiPopover-paper{
                        top: 240px !important;
                        .MuiMenu-list{
                            height: 250px;
                        }
                        @-moz-document url-prefix() {
                            .MuiMenu-list{
                                width: 82px;
                            }
                        }
                    }
                    [class*="materialui-daterange-picker-makeStyles-dateRangeBackdrop-"] {
                        display: none !important;
                        
                    }
                    [class*="materialui-daterange-picker-MuiPaper-elevation5-"],.MuiPaper-elevation5 {
                        box-shadow: none;
                    }
                    [class*="materialui-daterange-picker-MuiGrid-container-"] {
                        justify-content: space-around;
                    }
                    [class*="materialui-daterange-picker-makeStyles-headerItem-"]{
                        display: none;
                    }
                    [class*="materialui-daterange-picker-makeStyles-header-"] {
                        padding: 0;
                    }
                    hr{
                        height: 0;
                        margin: 0;
                        background-color: white;
                    }
                }
                .generate-btn{
                    display: flex;
                    justify-content: center;
                    margin: 1rem 0 1rem;
                    button{
                        &:disabled{
                            background-color: gray;
                            cursor: not-allowed;
                            border: none;
                        }
                    }
                }
                .err{
                    text-align: center;
                    color: red;
                    margin: 5px 0 1rem;
                    font-weight: 500;
                }
            }
        }
    }
}
