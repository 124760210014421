/* Home Page */

//Icons
%label-icon{
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 2px 2px;
}
.pickup{
    @extend %label-icon;
    background-image: url(../../images/icons/icon-pickup.svg);
    .link{
        padding-left: 5px;
        cursor: pointer;
    }
}

.dropoff{
    @extend %label-icon;
    background-image: url(../../images/icons/icon-dropoff.svg);
}


#bungii-tab-getEstimate, #bungii-tab-deliveryDetails, #bungii-tab-done{
    pointer-events: none;
    &.active{
        pointer-events: all;
    }
}