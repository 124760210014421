// /* FORM ELEMENTS */

// /* Default Placeholders */

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $dark-grey1;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $dark-grey1;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: $dark-grey1;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: $dark-grey1;
}

// /* Default Styling overrides */

select::-ms-expand {
	display: none;
}

input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

// /* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
	-webkit-text-fill-color: $black;
}

// /* TYPES OF FORM CONTROLS */

// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]
input,
textarea,
select {
	@include prefix(appearance, none);
	border: 1px solid $dark-grey1;
	line-height: 1;
	padding: 5px;
	outline: 0;
	resize: none;
	border-radius: 5px;

	&:focus,
	&:active,
	&:hover {
		outline: 0;
	}
	/* Different types of input */
	&[type="text"] {
	}

	&[type="checkbox"] {
	}

	&[type="radio"] {
		@include prefix(appearance, radio);
		height: 22px;
		width: 22px;
		margin-right: 9px;
		float: left;
	}

	&[type="search"] {
	}

	&[type="submit"],
	&[type="button"],
	&[type="reset"] {
	}

	&[type="submit"] {
	}

	&[type="button"] {
	}

	&[type="submit"] {
	}
}

input {
	padding: 9px 10px;
}

.form-content {
	margin-top: 11px;

	.form-label a {
		cursor: pointer;
		color: $blue;
		&:hover {
			color: $dark-blue;
		}
	}

	i {
		cursor: pointer;
	}
}
.form-wo-solo-duo {
	margin-top: -8px;
}
.form-group {
	margin-bottom: 30px;
}

// labels
.form-label {
	@include font-size(14px);
	text-transform: uppercase;
	margin-bottom: 3px;
	cursor: text;

	small {
		@include font-size(9px);
		color: $dark-grey2;
		font-weight: $font-weight-semibold;
	}
}

.form-check {
	background-color: $light-grey3;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid $light-grey2;
	height: 54px;
}
.form-check-label {
	@include font-size(18px);
	margin-top: 3px;

	&::before {
		content: "";
		position: absolute;
		border: 2px solid $light-grey4;
		height: 22px;
		width: 22px;
		border-radius: 11px;
		left: 12px;
		top: 12px;
	}
}

.form-check-input {
	margin-left: 0;
	position: relative;
	margin-right: 5px;
	visibility: hidden;

	&:checked + .form-check-label {
		&:before {
			border-color: $blue;
		}

		&:after {
			content: "";
			position: absolute;
			height: 12px;
			width: 12px;
			background: $blue;
			border-radius: 6px;
			left: 17px;
			top: 17px;
		}
	}
}
.form-control {
	height: calc(1.5em + 0.75rem + 4px);
}

.form-control.is-invalid {
	background-image: none;
}
.form-control.is-invalid:focus{
    box-shadow: none;
}

.form-control,
select {
	position: relative;
	border-color: $dark-grey1;
	&.load-time-select,
	&.modal-input.date {
		background: url(../../images/icons/caret.png) no-repeat;
		background-size: 23px auto;
		background-position: center right;
	}
}

.col-padding {
	margin-left: -6px;
	margin-right: -6px;
	> div {
		padding: 0 6px;
	}
}
.form-check-desc {
	@include font-size(11px);
	font-weight: $font-weight-semibold;
	position: absolute;
	right: 17px;
	top: 34px;
	color: $light-grey4;
	.sku-list {
		.toolTipOverlay {
			list-style: none;
			margin-left: -15px;
		}
	}
}
%trip-type-icons {
	position: absolute;
}
.solo,
.duo {
	position: relative;
}
.solo-img {
	@extend %trip-type-icons;
	height: 28px;
	width: 97px;
	right: 13px;
	top: 4px;
	opacity: 0.9 !important;
	&.one {
		height: 30px;
		width: 14px;
		right: 20px;
		top: 11px;
		opacity: 0.9 !important;
	}
}
.duo-img {
	@extend %trip-type-icons;
	height: 28px;
	width: 97px;
	right: 13px;
	top: 4px;
	opacity: 0.9 !important;
	&.two {
		height: 30px;
		width: 28px;
		right: 13px;
		top: 11px;
		opacity: 0.9 !important;
	}
}
.UIType {
	.form-check {
		height: 118px;
	}
	.form-check-label {
		width: 100%;
		height: 94%;
		cursor: pointer;
		z-index: 2;
		position: absolute;
		left: 0;
		top: 0px;
		padding-left: 41px;
		padding-top: 10px;
	}
	.form-check-desc {
		left: 50px;
		top: 44px;
	}
	ul {
		padding-left: 25px;
	}
	.duo-img,
	.solo-img {
		top: 14px;
	}
	&.UIType-3 {
		.form-check-desc {
			left: 41px;
			top: 46px;
		}
	}
}
.UIType-1 {
	.form-check {
		height: 62px;
	}
	.solo-img,
	.duo-img {
		top: 10px;
	}
	.form-check-desc {
		top: 40px;
	}
	.form-check-label {
		padding-top: 14px !important;

		&::before {
			top: 16px !important;
		}
		&:after {
			top: 21px !important;
		}
	}
}
.UIType-4 {
	.form-check-label {
		padding-top: 3px !important;

		&::before {
			top: 6px !important;
		}
		&:after {
			top: 11px !important;
		}
	}
	.form-check-desc {
		right: 131px;
		&.pros {
			right: 121px;
		}
	}
	.solo-img {
		width: 55px;
		height: auto;
		top: 15px;
	}
	.duo-img {
		width: 61px;
		height: auto;
		top: 17px;
	}
	@media (max-width: 1200px) {
		.form-check-desc {
			right: 98px;
			&.pros {
				right: 87px;
			}
		}
		.form-check-label {
			@include font-size(16px);
		}
	}
}

.UIType-5 {
	.form-check {
		height: 85px;
	}
	.duo-img {
		top: 17px;
	}
	.solo-img {
		top: 14px;
	}
	.form-check-label {
		font-weight: 600;
		margin-top: 0px;
	}
	.form-check-desc {
		top: 42px;
		left: 18px;
		&.pros {
			right: 121px;
		}
	}
}

.partner-disclaimer-mge {
	padding-left: 24px;
	li {
		font-size: 13px;
		opacity: 0.8;
	}
}
.description-value {
	margin-bottom: 0;
	padding-left: 24px;
	li {
		font-size: 13px;
		opacity: 0.8;
	}
}
.description-value {
	margin-bottom: 0;
	padding-left: 24px;
	li {
		font-size: 13px;
		opacity: 0.8;
	}
}
.vanity_item_box{
	margin-top: 10px;
	border-radius: 5px;
	border: 1px solid #dedede;
	padding: 10px 15px;
	background: #FFFEF2;

	.form-description{
		font-size: 13px;
		font-weight: 400;
		color: #131415;
		opacity: 0.8;
	}
	.form-check{
		background-color: inherit;
		border: none;
		padding: 0px;
		height: auto;
		.form-check-label{
			margin-top: 10px;
			// margin-bottom: 5px;
			padding-left: 15px;
		}
	}
}
.support-text{
	a{
		color: #316ED4;
	}
}

.custom_quote_disc {
	margin-top: 10px;
	border-radius: 5px;
	border: 1px solid #dedede;
	padding: 10px 15px;
	background-color: #fafafa;
	.col-8 {
		font-size: 13px;
		opacity: 0.8;
	}
	.col {
		display: flex;
		align-items: center;
		font-size: 0.9rem;
		text-align: center;
	}
	h5 {
		opacity: 1;
		font-size: 1.125rem;
		font-weight: 500;
		margin-bottom: 3px;
	}
	a {
		cursor: pointer;
		color: $blue;
		font-weight: 600;
		&:hover {
			color: $dark-blue;
		}
	}
	a:first-letter {
		text-transform: capitalize;
	}
}

.solo,
.duo,
.payment-method {
	.form-check-label {
		width: 100%;
		height: 94%;
		cursor: pointer;
		z-index: 2;
		position: absolute;
		left: 0;
		top: 0;
		padding-left: 41px;
		padding-top: 10px;
	}
}

.load-time {
	display: flex !important;
	.MuiOutlinedInput-root {
		&.invalid {
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
			&:hover {
				border: 1px solid #ca2a2a;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
	}
}
.pickup-address {
	.MuiOutlinedInput-root {
		height: 41px;
		width: 100%;
		position: inherit;
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
		&.invalid {
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
			&:hover {
				border: 1px solid #ca2a2a;
			}
		}
	}
}

.load-time-select {
	height: 42px;
	width: 262px;
}
.service-title {
	white-space: normal !important;
	@include font-size(16px);
}
.wrap-desc {
	white-space: normal !important;
	@include font-size(13px);
	opacity: 0.8;
	line-height: 17px;
}
.service-menu {
	.service-cost {
		@include font-size(16px);
		font-weight: 600;
		font-family: "Open Sans", sans-serif;
		color: #71767a;
	}
	.service-title {
		font-weight: 500;
	}
	.space-below {
		margin-bottom: -5px;
	}
	.col-padding {
		width: 100%;
	}
}

.MuiPopover-root .MuiPaper-root .MuiList-root .MuiMenuItem-root {
	white-space: normal;
}

.MuiPopover-root > :first-child {
	background-color: #000000 !important;
	opacity: 0.2;
}
.MuiSelect-icon {
	color: #131415 !important;
}
.service-level-dropdown {
	.MuiInputBase-input {
		font-family: "Open Sans", sans-serif;
	}
	.MuiFormControl-root {
		display: block;
	}
	.service-title {
		color: #131415;
	}
	.MuiOutlinedInput-input {
		padding: 10.5px 14px;
	}
	.service-title {
		@include font-size(16px);
		margin-bottom: 4px;
	}
	.service-desc {
		@include font-size(13px);
		opacity: 0.8;
	}
	.MuiSelect-iconOutlined {
		top: 10px;
	}
	.addHighliter {
		fieldset,
		.service-data {
			animation: fadeoutBg 3s; /***Transition delay 3s fadeout is class***/
			-moz-animation: fadeoutBg 3s; /* Firefox */
			-webkit-animation: fadeoutBg 3s; /* Safari and Chrome */
			-o-animation: fadeoutBg 3s; /* Opera */
		}
		@keyframes fadeoutBg {
			from {
				opacity: 0.2;
				background-color: #feee00;
			} /** from color **/
			to {
				opacity: initial;
			} //color: #131415; background-color: white; } /** to color **/
		}

		@-moz-keyframes fadeoutBg {
			/* Firefox */
			from {
				opacity: 0.2;
				background-color: #feee00;
			}
			to {
				opacity: initial;
			}
		}

		@-webkit-keyframes fadeoutBg {
			/* Safari and Chrome */
			from {
				opacity: 0.2;
				background-color: #feee00;
			}
			to {
				opacity: initial;
			}
		}

		@-o-keyframes fadeoutBg {
			/* Opera */
			from {
				opacity: 0.2;
				background-color: #feee00;
			}
			to {
				opacity: initial;
			}
		}
	}
	p {
		@include font-size(18px);
		margin-bottom: -5px;
		display: inline-block;
	}
	.link {
		cursor: pointer;
	}
	.recommend {
		text-transform: uppercase;
		@include font-size(10px);
		background-color: #fedf04;
		padding: 3px 8px;
		border-radius: 3px;
		font-weight: 600;
		opacity: 0.8;
		margin-left: 8px;
		vertical-align: middle;
	}
	.MuiOutlinedInput-root {
		width: 100%;
		&.invalid {
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
			&:hover {
				border: 1px solid #ca2a2a;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
	}
}

/* IE9, IE10, IE11 */
@media screen and (min-width: 0\0) {
	.wrap-desc,
	.service-title {
		display: flex;
		width: 510px;
	}
}
.GetEstimates-serviceOptions-2 {
	.MuiListItem-root {
		line-height: 1.2;
	}
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: rgba(48, 107, 207, 0.12) !important;
}
.MuiListItem-button:hover {
	background-color: rgba(48, 107, 207, 0.06) !important;
}

.pickup-time {
	display: block !important;
	.MuiOutlinedInput-root {
		height: 42px;
		width: 100%;
		@media (min-width: 992px) {
			width: auto;
		}
		background: url(../../images/icons/time.png) no-repeat 10px 12px;
		background-size: 16px;
		.MuiOutlinedInput-input {
			padding-left: 32px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
		&.invalid {
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
			&:hover {
				border: 1px solid #ca2a2a;
			}
		}
	}
}
.pickup-date {
	display: block !important;
	margin-bottom: 30px !important;
	.MuiOutlinedInput-root {
		height: 42px;
		width: 100%;
		background: url(../../images/icons/calendar.png) no-repeat 13px 13px;
		background-size: 16px;
		.MuiOutlinedInput-input {
			padding-left: 32px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
	}
}
//Handling pickup-time above 1024px.
.pickup-time-box {
	@media (min-width: 1024px) {
		.MuiMenu-paper {
			top: auto !important;
			bottom: 61px;
		}
	}
}
.pickup-time-wo-sd {
	@media (min-width: 1024px) {
		.MuiMenu-paper {
			top: auto !important;
			bottom: 280px;
		}
	}
}

//Captcha
.gcaptcha {
	margin-top: 0;
}

// /*Custom checkbox */
.payment-methods {
	span {
		// display: none;
	}
}
.customFields {
	.MuiFormControl-root {
		display: block;
		.MuiInputBase-root {
			width: 100%;
			.MuiSelect-root {
				width: 100%;
			}
		}
	}

	.MuiOutlinedInput-root {
		width: 240px;
		height: calc(1.5em + 0.75rem + 4px);
		&.invalid {
			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
			&:hover {
				border: 1px solid #ca2a2a;
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
		}
		.MuiSelect-select {
			&:focus {
				background: transparent;
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #a2a2a2 !important;
				border-width: 1px !important;
			}
		}
	}
}

//Clear field icon for Google autocomplete
.clear-field {
	position: absolute;
	height: 30px;
	width: 32px;
	right: 4px;
	top: 31px;
	border-radius: 5px;
	background: url(../../images/icons/close.png) no-repeat center;
	background-size: 10px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 1);
}

.search-field {
	position: absolute;
	right: 36px;
	top: 31px;
	background: url(../../images/icons/search.png) no-repeat center;
	background-size: 15px;
	height: 30px;
	width: 32px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 1);
	border-right: 1px solid #a2a2a2;
	z-index: 9;
}
.search-blue {
	position: absolute;
	right: 36px;
	top: 31px;
	background: url(../../images/icons/search-blue.svg) no-repeat center;
	background-size: 15px;
	height: 30px;
	width: 32px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 1);
	border-right: 1px solid #a2a2a2;
	z-index: 9;
}

//Nonce added indicator
.nonce-added {
	label {
		margin-top: -2px;
	}
	span {
		display: block;
		position: absolute;
		left: 49px;
		top: 31px;
		@include font-size(11px);
		color: $blue;
		font-style: italic;
	}
}

// .invalid{
//     border: 1px solid #dc3545;;
// }

//Delivery Details form
.delivery-details {
	margin-top: 9px;

	.payment-methods.form-group {
		margin-bottom: 5px;
	}
	.items-to-deliver {
		.form-group {
			margin-bottom: 30px;
		}
	}

	> .row {
		max-width: 600px;

		.form-group {
			// padding-right: 30px;
			margin-bottom: 30px;

			@media (min-width: 992px) {
				margin-bottom: 15px;
			}
		}
	}
	.sku-text {
		border-radius: 0.25rem 0 0 0.25rem;
		display: inline-block;
		max-width: 280px;
	}

	.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
		border-color: #CA2A2A !important;
	}
	
	.MuiInput-underline:before {
		border-bottom: none;
	}
	.MuiInput-underline:hover:not(.Mui-disabled):before{
		border-bottom: none;
	}
	// Pallet Dimensions label fix
	.product-desc {
		input {
			@media (min-width: 768px) {
				margin-bottom: 0;
			}
			margin-bottom: 30px;
		}
		.dimensions-input{
			display: block;
		}
		.mb10 {
			margin-bottom: 10px;
		}
		.pr0{
			padding-right: 0;
		}
		.pl15{
			padding-left: 15px;
		}
		.pr15{
			padding-right: 15px;
		}
		.multiply-sign{
			padding: 7px 0 0 5px;
		}
		.w80{
			width: 80%;
		}
		.w20{
			width: 20%;
		}
		.w33{
			width: 33%;
		}
		.unit{
			position: absolute;
			display: block;
			top: 8px;
			z-index: 9;
			color: #6c757d;
			right: 0;
			padding-right: 10px;
		}
		.col-md-3 {
			&:nth-child(2) {
				@media (min-width: 768px) {
					// padding-left: 0;
					// padding-right: 0;
				}
			}
			&:nth-child(3) {
				input {
					margin-bottom: 10px;
				}
			}
		}
	}
	.pallet {
		opacity: 0.5;
		margin-top: 10px;
	}
	.pallet-2 {
		margin-top: 10px;
	}
	.sku-btn {
		border-radius: 0 5px 5px 0;
		max-height: 40px;
	}
	#add-items-btn{
		height: 40px;
		&.btn:disabled{
			background-color: #A2A2A2;
			border-color: #A2A2A2;
		}
	}
	.sku-table {
		margin-bottom: 30px;
		margin-top: -14px;
		th {
			@include font-size(13px);
			color: #131415;
			font-weight: normal;
			border-bottom: 1px solid #a2a2a2;
			border-top: none;
			padding-bottom: 6px;
			&:first-child {
				padding-left: 0;
				padding-right: 0;
			}
			&:nth-child(2),
			&:nth-child(3) {
				@media (max-width: 576px) {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		td {
			border-bottom: 1px solid #a2a2a2;
			vertical-align: middle;
			&:first-child {
				@include font-size(13px);
				color: #131415;
				opacity: 0.5;
				width: 90px;
				padding-left: 0;
				padding-right: 0;
				@media (max-width: 576px) {
					width: 60px;
				}
			}
			&:nth-child(2) {
				@include font-size(16px);
				opacity: 1;
				line-height: 1.2;
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				@media (max-width: 576px) {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		.delete {
			cursor: pointer;
			img {
				width: 13px;
				height: auto;
			}
		}
		.quantity {
			height: 25px;
			width: 45px;
			padding: 9px 0 9px 10px;
			@media screen and (min-width: 0\0) {
				padding: 4px;
			}
		}
	}
	.items-table{
		tfoot{
			font-size: 0.875rem;
			font-weight: 700;
			background-color: #EBEDED;
			td{
				padding: 0.50rem 0.75rem !important;
			}
		}
		tbody, tfoot {
			tr{
				&:last-child{
					td{
						border: none;
					}
				}
			}
		}
		th {
			@include font-size(14px);
			color: #131415;
			font-weight: normal;
			border-bottom: 1px solid #a2a2a2;
			border-top: none;
			padding-bottom: 6px;
			&:first-child {
				padding-left: 0;
				padding-right: 0;
			}
			&:nth-child(2),
			&:nth-child(3) {
				@media (max-width: 576px) {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		td {
			border-bottom: 1px solid #a2a2a2;
			vertical-align: middle;
			&:first-child {
				@include font-size(14px);
				color: #131415;
				padding-left: 0;
				padding-right: 0;
			}
			&:nth-child(2) {
				@include font-size(14px);
				opacity: 1;
				line-height: 1.2;
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				@media (max-width: 576px) {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		.quantity {
			height: 25px;
			width: 45px;
			padding: 11px 0 9px 10px;
			@media screen and (min-width: 0\0) {
				padding: 4px;
			}
		}
	}
	.custom-placeholder {
		font-size: 10px;
		text-transform: initial;
		display: block;
	}
	.close-details {
		transform: rotate(180deg);
	}
	.counter {
		display: inline;
		margin-left: -14px;
		.increment {
			margin-bottom: 14px;
			margin-left: -10px;
			width: 10px;
			height: auto;
			cursor: pointer;
		}
		.decrement {
			margin-top: 8px;
			width: 10px;
			height: auto;
			cursor: pointer;
		}
	}
	.cust-phone {
		position: relative;
		margin-bottom: 30px;
		@media (min-width: 576px) {
			margin-bottom: 4px;
		}
		.add-btn {
			width: 150px;
			text-align: center;
			// position: absolute;
			// top: 22px;
			// right: -125px;
			// margin-bottom: 15px;
			cursor: pointer;
			font-weight: 600;
			color: $blue;
			padding: 8px 0;
			@include font-size(14px);

			&:hover {
				color: $dark-blue;
			}
		}
		.disabled {
			color: $dark-grey2;
			&:hover {
				color: $dark-grey2;
				cursor: not-allowed;
			}
		}
		.close {
			position: absolute;
			width: 12px;
			right: 28px;
			top: 51px;
			cursor: pointer;
		}
	}

	h2 {
		margin-top: 10px;
	}
}

//Login form
.height-100 {
	height: 100vh;
}

.select-pallets{
	.MuiSelect-select{
		padding: 10px !important;
	}
	.Mui-focused {
		.MuiOutlinedInput-notchedOutline {
			border-color: #a2a2a2 !important;
			border-width: 1px !important;
		}
	}
	.MuiSelect-select:focus {
		border-radius: 0;
		background: none;
	}
}
