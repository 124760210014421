// /*  ------ Loader Start -----*/
// .loader{
//   z-index: 2000;
//   display: block;
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   opacity:0.4;
// }

// .loader-inner{
//   z-index: 1;
//   text-align: center;
//   top: 50%;
//   transform: translateY(-50%);
//   position: absolute;
//   left: 0;
//   right: 0;
// }

// @-webkit-keyframes ball-scale {
// 0% {
//   -webkit-transform: scale(0);
//           transform: scale(0); }
// 100% {
//   -webkit-transform: scale(1);
//           transform: scale(1);
//   opacity: 0; } }

// @keyframes ball-scale {
// 0% {
//   -webkit-transform: scale(0);
//           transform: scale(0); }
// 100% {
//   -webkit-transform: scale(1);
//           transform: scale(1);
//   opacity: 0; } }

// .ball-scale > div {
//   background-color: #fff;
//   width: 15px;
//   height: 15px;
//   border-radius: 100%;
//   margin: 2px;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
//   display: inline-block;
//   height: 60px;
//   width: 60px;
//   -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
//   animation: ball-scale 1s 0s ease-in-out infinite; 
// }

// @keyframes ball-scale {
// 0% {
//   -webkit-transform: scale(0);
//           transform: scale(0); }
// 100% {
//   -webkit-transform: scale(1);
//           transform: scale(1);
//   opacity: 0; } }

// .ball-scale > div {
// background-color: #fff;
// width: 15px;
// height: 15px;
// border-radius: 100%;
// margin: 2px;
// -webkit-animation-fill-mode: both;
//         animation-fill-mode: both;
// display: inline-block;
// height: 60px;
// width: 60px;
// -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
//         animation: ball-scale 1s 0s ease-in-out infinite; }

// .ball-scale-random {
// width: 37px;
// height: 40px; }
// .ball-scale-random > div {
//   background-color: #fff;
//   width: 15px;
//   height: 15px;
//   border-radius: 100%;
//   margin: 2px;
//   -webkit-animation-fill-mode: both;
//           animation-fill-mode: both;
//   position: absolute;
//   display: inline-block;
//   height: 30px;
//   width: 30px;
//   -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
//           animation: ball-scale 1s 0s ease-in-out infinite; }
//   .ball-scale-random > div:nth-child(1) {
//     margin-left: -7px;
//     -webkit-animation: ball-scale 1s 0.2s ease-in-out infinite;
//             animation: ball-scale 1s 0.2s ease-in-out infinite; }
//   .ball-scale-random > div:nth-child(3) {
//     margin-left: -2px;
//     margin-top: 9px;
//     -webkit-animation: ball-scale 1s 0.5s ease-in-out infinite;
//             animation: ball-scale 1s 0.5s ease-in-out infinite; }

// /*  ------ Loader End -----*/

.loader{
    background-color: rgba(58,67,76,0.5);;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity:1 !important;
    z-index: 9999999;
    img{
      width: 120px;
      height: 120px;
      z-index: 9999;
    }
}