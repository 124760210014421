.Toastify__toast-container {
	z-index: 999999;
	width: auto !important;
	max-width: 500px !important;
	min-width: 350px !important;
	@media (min-width: 1200px) {
		max-width: 550px !important;
	}
	&.Toastify__toast-container--bottom-right {
		bottom: 3em;
		right: 3em;
		@media (min-width: 1200px) {
			bottom: 4em;
			right: 4em;
		}
	}
}
.Toastify__toast {
	padding: 13px 42px 13px 20px !important;
	box-shadow: none;
	border-radius: 8px !important;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.43), 0 2px 15px 0 rgba(0, 0, 0, 0.08) !important;
	min-height: 54px !important;
	margin-bottom: 0 !important;
	@media (max-width: 480px) {
		margin-left: 15px;
	}
}
.Toastify__toast--error {
	background: #000000 !important;
	color: #c8c8c8;
}
.Toastify__toast--success {
	background: #6bab36 !important;
}
.Toastify__close-button {
	top: 50%;
	transform: translateY(9px);
	font-size: 0 !important;
	&:after {
		content: "";
		background: url(../../images/icons/close-white.png) no-repeat !important;
		width: 16px;
		height: 16px;
		position: absolute;
		z-index: 1;
		right: -23px;
		top: -4px;
	}
}
