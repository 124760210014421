/* HELPERS */

/* background colors */

@each $bg-color, $color in $bg-color {
	.bg-#{$bg-color} {
		background: $color;
	}
}

@each $text-color, $color in $text-color {
	.text-#{$text-color} {
		color: $color;
	}
}

/* Position relative */
.position-relative {
	position: relative;
}

/* Height */
.height-195 {
	height: 195px;
}

/* margins */
.m-r-5 {
	margin-right: 5px;
}
.m-r-15 {
	margin-right: 15px;
}
.m-r-20 {
	margin-right: 20px;
}
.m-l-3 {
	margin-left: 3px;
}
.m-l-10 {
	margin-left: 10px !important;
}
.m-l-15 {
	margin-left: 15px !important;
}
.m-t-0{
	margin-top: 0 !important;
}
.m-t-10 {
	margin-top: 10px !important;
}
.m-t-15 {
	margin-top: 15px !important;
}
.m-t-9 {
	margin-top: 9px !important;
}
.m-t-20 {
	margin-top: 20px !important;
}
.m-t-40 {
	margin-top: 40px !important;
}
.m-b-5 {
	margin-bottom: 5px !important;
}
.m-b-20 {
	margin-bottom: 20px !important;
}
.m-b-7 {
	margin-bottom: 7px !important;
}
.m-b-10 {
	margin-bottom: 10px !important;
}
.m-b-22 {
	margin-bottom: 22px !important;
}
.m-b-30 {
	margin-bottom: 30px !important;
}

/* Paddings */
.p-l-0 {
	padding-left: 0;
}
.p-r-0 {
	padding-right: 0;
}
.p-r-10 {
	padding-right: 10px;
}
.pr-lg-10 {
	@media (min-width: 992px) {
		padding-right: 10px !important;
	}
}
.p-l-7 {
	padding-left: 7px;
}
.p-l-10 {
	padding-left: 10px;
}
.p-r-9 {
	padding-right: 9px;
}
.p-r-40 {
	padding-right: 40px;
}
.p-10{
	padding: 10px !important;
}
.text-grey {
	color: $dark-grey !important;
}
.p-l-18 {
	padding-left: 18px;
}
.p-t-10 {
	padding-top: 10px;
}
.pointer-none {
	pointer-events: none;
}
.pointer-auto {
	pointer-events: auto;
}
.color-red {
	color: $red;
}
.color-dark-grey {
	color: $dark-grey;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.max-width-100 {
	max-width: 100% !important;
}