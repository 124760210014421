/* Navigations/Menus */
.nav-tabs {
	margin-top: 50px;
	margin-bottom: 0;
	@media (min-width: 992px) {
		margin-top: 0;
		margin-bottom: 20px;
	}
	counter-reset: my-sec-counter;
	justify-content: flex-end;
	position: relative;
	cursor: text;
	border-bottom: 1px solid $dark-grey1;
	&:after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 69%;
		height: 0.5px;
		@media (max-width: 992px) {
			background-color: transparent;
		}
	}
	.nav-link {
		border: 0;
		width: 121px;
		padding: 9px 0 16px;
		font-weight: $font-weight-semibold;
		@include font-size(12px);
		text-transform: uppercase;
		line-height: 1;
		color: $dark-grey1;
		padding-left: 43px;
		position: relative;
		border-bottom: 1px solid $light-grey1;
		margin-bottom: -1px;
		pointer-events: none;

		&:before {
            counter-increment: my-sec-counter +1;
			content: counter(my-sec-counter);
			@include font-size(15px);
			border: 1px solid $light-grey2;
			width: 33px;
			height: 33px;
			color: $color-primary;
			display: flex;
			text-align: center;
			flex-direction: column;
			justify-content: center;
			border-radius: 19px;
			position: absolute;
			left: 1px;
			top: 4px;
			padding-top: 2px;
		}
		&.completed {
			border-bottom: 1px solid $dark-grey1;
			&:before {
				background-color: #feee00;
				background-image: url(../../images/icons/checked.png);
				background-repeat: no-repeat;
				background-position: center;
				border: none;
				content: "";
			}
		}

		&.active {
			opacity: 1;
			border-color: $dark-grey1;
			color: inherit;
			cursor: text;
			&:before {
				background-color: $color-secondary;
				border: 0;
				width: 34px;
				height: 34px;
				padding-top: 1px;
			}
			&:after {
				content: "";
				width: 23px;
				height: 23px;
				position: absolute;
				bottom: -2px;
				right: 0;
				background: url(../../images/icons/progress.png) no-repeat bottom right;
				background-size: 23px;
			}
		}
		&:first-child {
			&.get-quote {
				padding-top: 17px;
				width: 136px;
			}
			width: 132px;
			padding-right: 18px;
			border-bottom: 1px solid $dark-grey1;
			opacity: 1;
		}

		&:last-child {
			width: 103px;
			padding-top: 17px;

			&:before {
				background-image: url(../../images/icons/flag.png);
				background-position: center;
				background-repeat: no-repeat;
				content: "";
			}
		}
	}
}
